.top-container {
    @extend %contenedor;

    .secondary-btn-container {
        margin-top: 1rem;
    }
    .top-table {
        text-align: center;
        border: 1px solid $colorRojoMarca;
        border-radius: 15px;
        overflow: auto;

        .top-table-note {
            font-size: 1rem;
            color: $colorContenidoBlanco;
            display: block;
            margin: 0.5rem;
        }
        .top-table-title {
            font-size: 1.5rem;
            color: $colorRojoMarca;
            margin: 0.5rem;
        }
        .top-table-table {
            border-collapse: collapse;
            width: 100%;
            background-color: $colorContenidoBlanco;
            border-radius: 15px;
            font-size: 1.1rem;

            .table-headers td, .table-item td{
                padding: 0.5rem;
            }
            .table-headers td{
                font-weight: bold;
                color: $colorContenidoBlanco;
                background-color: $colorRojoMarca;
                &:nth-child(1) {
                    border-top-left-radius: 15px;
                }
                &:nth-last-child(1) {
                    border-top-right-radius: 15px;
                }
            }
            .table-headers, .table-item {
                & td + td {
                    border-left: #000 1px solid;
                }
            }
            .table-headers + .table-item, .table-item + .table-item {
                border-top: $colorRojoMarca 1px solid;
            }
        }
    }
}