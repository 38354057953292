.banner {
background-color: $colorSeccion5;
    .banner-img {
        display: block !important;
        width: 100%;
        margin: auto;

        &.mobile {
            @media only screen and (min-width: 678px) {
                display: none !important;
            }
        }
        &.desktop-l {
            @media only screen and (max-width: 1439px) {
                display: none !important;
            }
        }
        &.desktop {
            @media only screen and (max-width: 677px) {
                display: none !important;
            }
            @media only screen and (min-width: 1440px) {
                display: none !important;
            }
        }
    }
}