.prize-container {
    margin-top: 4rem;

    .prize-gallery {
        display: flex;
        justify-content: center;
        column-gap: 1.5rem;
        row-gap: 3rem;
        flex-wrap: wrap;

        .prize {
            background-color: $colorRojoMarca;
            border-radius: 20px;
            max-width: 300px;
            display: flex;
            flex-direction: column;
            .prize-box1 {
                position: relative;
                background-color: $colorContenidoBlanco;
                border-radius: 20px;
                border: 1px solid $colorTextoBase;
                padding: 1.5rem 1rem;
                .prize-img {
                    display: block;
                    margin: auto;
                    width: 76px;
                    position: absolute;
                    top: -38px;
                    left: 50%;
                    transform: translateX(-38px);
                }
                .prize-target {
                    margin-top: 24px;
                    color: $colorRojoMarca;
                    text-align: center;
                    font-size: 2rem;

                    .anotacion {
                        color: #000;
                        font-weight: normal;
                        font-size: 1rem;
                        display: inline-block;
                        margin-top: 0.5rem;
                    }
                }
            }
            .prize-detail {
                margin: auto;
                padding: 1.5rem 2rem;
                font-size: 1.2rem;
                text-align: center;
                color: $colorContenidoBlanco;
            }
        }
    }
}