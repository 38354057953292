.footer {
    margin-bottom: 1rem;
    .footer-logo-container {
        background-color: $colorRojoMarca;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4rem 5%;
        .footer-logo {
            img {
                width: 100%;
                height: auto;
                max-height: 2rem;

            }
        }
    }
    .legal {
        @extend %contenedor;
        margin-top: 1rem;
        ul {
            display: flex;
            justify-content: space-between;
            li {
                line-height: 0;
                img {
                    display: inline-block;
                    width: 100%;
                    max-height: 55px
                }
            }
        }
        p {
            margin-top: 1rem;
            font-size: 0.5rem;
        }
        p + p{
            margin-top: 0.2rem;
        } 
    }
}