* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body, input{
    font-family: DMSans;
    color: $colorTextoBase;
}

html, body {
    max-width: 100%;
}

body {
    background-color: #f0f0f0;
}

h2 {
    font-size: 1.8rem;
    font-weight: 400;
}

button {
    background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

a {
    color: inherit;
    text-decoration: none;
    transition: transform .1s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }
    &:active {
        transform: scale(.95);
    }
}

ul li {
    list-style: none;
}

.text-bold {
    font-weight: 700;
}

.italics {
    font-style: italic;
}

.color-rojo {
    color: $colorRojoMarca;
}

.centered {
    text-align: center;
}

%contenedor{
    width: 90%;
    margin: auto;
    max-width: 1200px;
}