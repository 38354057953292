.section-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    
    .x-icon {
        height: 0.7rem;
        width: 0.7rem;
        img {
            display: inline-block;
            color: inherit;
        }
        @media only screen and (max-width: 576px) {
            display: none;
        }
    }
    
    .section-title {
        text-align: center;
        font-size: 3rem;
        scroll-margin-top: 60px;
    }
}