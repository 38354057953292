.ranking {
    background-color: #000;

    .section-title-container {
        padding-top: 2rem;
        margin-bottom: 1rem;
    }
    .subtitle-container {
        margin-bottom: 1.5rem;
    }
    .top-container {
        padding-bottom: 3rem;
    }
    .secondary-btn-container {
        margin-top: 1.5rem;
    }
}