.tus-datos-container {
    background-color: $colorRojoMarca;
    
    .tus-datos {
        @extend %contenedor;

        .section-title-container {
            padding-top: 2rem;
            margin-bottom: 1rem;
        }
        .subtitle-container {
            margin-bottom: 1.5rem;
        }
        .my-data-container {
            padding-bottom: 2rem;
        }
    }
}