.modal-container {
    background-color: #00000099;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal {
        width: 90%;
        max-width: 600px;
        padding: 4rem 3rem;
        background-color: #00000099;
        border-radius: 15px;
        border: solid 1px $colorContenidoBlanco;
        text-align: center;
        color: $colorContenidoBlanco;
        font-size: 1.2rem;
        position: relative;

        button {
            width: 1.2rem;
            position: absolute;
            top: 1rem;
            right: 1rem;

        }
    }
}