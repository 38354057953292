// COLORES

$colorContenidoBlanco: #fff;
$colorRojoMarca: #D51A00;
$colorTextoBase: #000;
$colorSeccion1: $colorRojoMarca;
$colorSeccion2: #DF3E37;
$colorSeccion3: #E55F58;
$colorSeccion4: #EB7F78;
$colorSeccion5: #F29F99;
$colorGrisClaro: #6e6e6e;
$colorFondoHero: linear-gradient(180deg, rgba(0,0,0,0.1972477064220184) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,1) 100%);