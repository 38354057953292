.navigation {
    background-color: $colorContenidoBlanco;
    .navigation-list {
        @extend %contenedor;
        display: flex;
        justify-content: center;
        .navigation-list-item {
            text-align: center;
            width: 33%;
            padding: 1rem 0;
            font-size: 1.2rem;
            font-weight: bold;
            text-transform: uppercase;

            button {
                display: inline-block;
                width: 100%;
                transition: none;
                transform: none;
            }
        }
        .navigation-list-item + .navigation-list-item {
            button {
                border-left: 1px solid $colorRojoMarca;
            }
        }
    }
}