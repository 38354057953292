.header {
    background-color: $colorRojoMarca;

    .header-container {
        @extend %contenedor;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.5rem 0;
        gap: 2rem;
        
        .header-logo {
            margin: auto;
            margin-right: 0;
            img {
                height: 1.5rem;

            }
            @media only screen and (max-width: 576px) {
                width: 45%;
                img {
                    width: 100%;
                    height: auto;
                    max-height: 1.5rem;
                }
            }
        }
    }
}