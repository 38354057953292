.secondary-btn-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .secondary-btn {
        padding: 0.5rem 3rem;
        background-color: $colorContenidoBlanco;
        border-radius: 40px;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-weight: bold;
        color: $colorRojoMarca;
    }
}