.premios {
    @extend %contenedor;

    .section-title-container {
        margin-top: 2rem;
        margin-bottom: 1rem;
    }
    .subtitle-container {
        margin-bottom: 0.5rem;
    }
    .prize-container {
        margin-bottom: 2rem;
    }
}