.my-data-container {
    overflow: auto;
    

    .my-data {
        width: 100%;
        background-color: $colorRojoMarca;
        border-collapse: collapse;
        .my-data-item {
            &:nth-child(1) {
                border-bottom: 1px solid #ffffff;

            }
            td {
                text-align: center;
                color: #ffffff;
                padding: 0.75rem;
            }
        }
    }
}