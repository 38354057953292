.spotlight-text-container {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        color: #fff;
        text-align: center;
        line-height: 2.5rem;
        font-size: 1.5rem;
        .spotlight-text {
            text-align: center;
            padding: 0.1rem 0.5rem;
            background-color: $colorRojoMarca;
        }
    }
}