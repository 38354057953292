@font-face {
    font-family: DMSans;
    src: url("../Fonts/DMSans-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: DMSans;
    src: url("../Fonts/DMSans-Italic.ttf");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: DMSans;
    src: url("../Fonts/DMSans-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: DMSans;
    src: url("../Fonts/DMSans-MediumItalic.ttf");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: DMSans;
    src: url("../Fonts/DMSans-BoldItalic.ttf");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: DMSans;
    src: url("../Fonts/DMSans-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}