.login-component, .change-password-component, .reset-password-component {
    background-color: $colorRojoMarca;
    position: relative;

    .login-container, .change-password-container, .reset-password-container {
        @extend %contenedor;
        position: absolute;
        top: 5%;
        right: 10%;
        
        
        .login, .change-password, .reset-password {
            margin: auto;
            margin-right: 0;
            padding: 2rem 3rem 1rem 3rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;
            background-color: #00000099;
            border-radius: 15px;
            border: solid 1px $colorContenidoBlanco;
            width: 90%;
            max-width: 380px;
            text-align: center;
            
            p {
                color: $colorContenidoBlanco;
                text-align: center;
                font-size: 1.2rem;
            }
            a {
                font-size: 1rem;
                color: $colorContenidoBlanco;
                transform: none;
            }
            input {
                padding: 0.5rem 1rem;
                width: 100%;
                border-radius: 40px;
                border: none;
                text-align: center;
            }
            .login-btn {
                width: 100%;
            }
            #login-btn {
                width: 100%;
                font-size: 1.2rem;
            }
            
            @media only screen and (max-width: 677px) {
                margin-right: auto;
                padding: 2rem 2rem 1rem 2rem;
            }
        }
        @media only screen and (max-width: 880px) and (min-width: 678px) {
            top: 0;
            .login{
                transform: scale(0.7);
            }
        }
        @media only screen and (max-width: 677px) {
            top: 20%;
            left: 0;
            right: 0;
        }
    }
}